@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/fonts" as *;
@use "../../Styles/Partials/colors" as *;

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  background-color: $jp-tan;

  @include tablet {
    flex-direction: row;
    align-items: center;
    gap: 40%;
    padding: 4rem 5%;
  }

  @include desktop {
    padding: 4rem 5%;
    

  }


&__details {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: $jp-red;
  text-align: left;
  


  @include tablet {
    display: flex;
    height: 100%;
    width: 100%;
    line-height: 2;
  }
}

&__details--heading {
background-color: $jp-tan;
color: $jp-red;

@include tablet {
  font-size: 1.2rem;
}
}

&__details--email {
text-decoration: underline;
}

&__details--number {
}

&__socials {
  width: 100%;
  height: 100%;
}


&__socials--container {
display: flex;
flex-direction: row;
justify-content: center;
gap: 5%;

@include tablet {
  gap: 25%;
}
}



&__copyright {
  color: $jp-red;

}



&__fb,
&__insta,
&__twitter {
background-image: url(../../icons/facebook.png);
width: 3.5rem;
height: 3.5rem;
background-size: cover;
margin: 1rem;

@include tablet {
width: 3.5rem;
height: 3.5rem;
cursor: pointer;
padding: 2rem;
}
}

&__insta {
background-image: url(../../icons/instagram.png); 
}

&__twitter {

}
}
 
i {
  display: none;
}
 

