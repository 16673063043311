@use "../../Styles/Partials/colors" as *;
@use "../../Styles/Partials/mixins" as *;

.contact {
  &__parallax {
    background-image: url(../../images/body/room.webp);
    @include main-image--small;

    @include tablet {
          @include main-image--med;

    }

    @include desktop {
          @include main-image--lrg;

    }
  }

  &__heading {
    padding: 3rem 1rem 1rem;
    font-size: 2rem;
    color: $jp-red;
    text-align: center;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    

    @include tablet {
     flex-direction: row;
    }

    @include desktop {
      flex-direction: row;
      padding: 0 2% 5% 2%;
    }
  }

  &__blurb {
    padding: 1rem;
    width: 100%;
    font-size: 1.2rem;

    @include tablet {
      width: 50%;
      padding: 1rem 2rem;
      font-size: 1.2rem;
      line-height: 1.4;
    }

    @include desktop {
      padding: 1rem 3rem;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;

  @include tablet {
    width: 50%;
    padding: 1rem 2rem;
  }

  @include desktop {
    width: 50%;
    padding: 1rem 3rem;
  }

  &__name,
  &__email,
  &__company,
  &__message {
    width: 100%;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #9ba4b5;
    background-color: #dde6ed;
    margin: 0.5rem 0 0;
  }
  ::-webkit-input-placeholder {
    color: #000;
    opacity: 80%;
  }

  &__message {
    height: 5rem;
    width: 9.2rem;
    resize: none;
    width: 100%;
    padding: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__button {
    padding: 0.5rem;
    margin-top: 0.2rem;
    width: 100%;
    border-radius: 4px;
    border: 0;
    background-color: #dde6ed;
    color: #000;
  }
}
