@use "../../Styles/Partials/colors" as *;
@use "../../Styles/Partials/mixins" as *;

body {
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.body {
  &__container {
    width: 100vw;
    height: 100vh;
  }

  &__heading {
    color: #76323f;
    color: $jp-tan;
    font-size: 4rem;
    width: 60%;
    margin: 20% 0 0 10%;
    z-index: 1;
  }

  &__flyer {
    height: 15rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__specialists {
    color: $jp-red;
    font-size: 3rem;
    font-weight: 700;
  }

  &__hidden {
    text-align: justify;
    color: $jp-red;
    width: 80%;

    @include desktop {
    font-size: 1.5rem;
    width: 60%;
    line-height: 1.4;
    }
  }

  &__clowns {
    padding: 1rem;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0.2rem;
  display: inline-block;
}

.slick-dots li button:before {
  font-size: 1.2rem;
  line-height: 0;
}

.slick-dots li.slick-active button:before {
  color: $jp-red;
}

.slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
}



.slide1 {
  background-image: url("../../images/body/inside-front.webp");
}

.slide2 {
  background-image: url("../../images/body/front-house.webp");
}

.slide3 {
  background-image: url("../../images/body/frames.webp");
}

.slide4 {
  background-image: url("../../images/body/room.webp");
}
