@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/colors" as *;

.about {
  background-color: #fff;

  &__image {
    background-image: url("../../images/body/plan.webp");
    @include main-image--small;

    @include tablet {
          @include main-image--med;

    }

    @include desktop {
          @include main-image--lrg;

    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 1rem ;

    @include tablet {
      display: flex;
      padding: 3rem;      
    }

    @include desktop {
      padding: 1rem 5%;
      height: 100%;

    }
  }

  &__heading {
    padding: 3rem 1rem 1rem;
    font-size: 2rem;
    color: $jp-red;
    text-align: center;
  }

  &__what {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    padding: 1.5rem 0 2rem 0;
    width: 100%;
    color: $jp-red;
  }

  &__wedo {
    font-size: 1.2rem;
    line-height: 1.3;

    @include desktop {
      font-size: 1.3rem;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  @include tablet {
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__box {
    padding: 2% 5%;

    @include tablet {
      padding: 1.5% 10%;
    }

    @include desktop {
      width: 33.333%;
      padding: 2%;
    }
  }

  &__clowns {
    text-align: center;
    padding-top: 1.5rem;
    font-size: 1.4rem;
  }

  &__box--heading {
    padding: 1rem;
    text-align: center;
    color: $jp-red;

    @include desktop {
    }
  }

  &__box--content {
    text-align: center;
    font-size: 1rem;

    @include desktop {
      font-size: 1.2rem;
    }
  }
}
