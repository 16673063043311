@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/colors" as *;

.steel {
  @include desktop {
  }

  &__main-image {
    background-image: url("../../images/marine/slab.webp");
    @include main-image--small;

    @include tablet {
          @include main-image--med;

    }

    @include desktop {
          @include main-image--lrg;

    }
  
  }

  &__heading {
    padding: 3rem 1rem 1rem;
    font-size: 2rem;
    color: $jp-red;
    text-align: center;

    @include desktop {
    }
  }

  &__container {
    @include desktop {
    }
  }

  &__blurb {
    font-size: 1.2rem;
    padding: 1rem 2.5rem;


    @include tablet {
      width: 80%;
      text-align: justify;
      margin: auto;
      font-size: 1.3rem;
      line-height: 1.3;
    }
  }

  &__thumbnails {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    gap: 1rem;

    @include tablet {

    }

    @include desktop {
    }
  }

  &__thumbnail {
    height: 25rem;
    width: 100%;

    @include tablet {
        width: calc(50% - 2rem);
        height: 30rem;
    }

    @include desktop {
        width: calc(33.33% - 2rem);
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include desktop {
    }
  }

  &__hidden {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.dock1 {
  background-image: url("../../images/marine/dockls.webp");
}

.dock2 {
  background-image: url("../../images/marine/slab.webp");
}

.dock3 {
  background-image: url("../../images/marine/form.webp");
}

.dock4 {
  background-image: url("../../images/marine/pouring.webp");
}

.dock5 {
  background-image: url("../../images/marine/rescue.webp");
}

.dock6 {
  background-image: url("../../images/marine/marina.webp");
}
