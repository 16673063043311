@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/fonts" as *;
@use "../../Styles/Partials/colors" as *;

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1rem;
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 1000;
  box-shadow: 5px -15px 5px 15px $jp-tan;

  @include tablet {
    height: 7rem;
    padding: 0 2%;
  }

  @include desktop {
    padding: 0 5%;
  }
}

img {
  width: 10rem;
  height: auto;

  @include tablet {
    width: 15rem;
    height: auto;
  }
}

.hamburger {
  background-image: url("../../icons/hamburger.png");
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  cursor: pointer;

  @include tablet {
    display: none;
  }
}

.logo-link {
    padding-top: .5rem;
    width: auto;
    height: auto;
}





.link {
  text-decoration: none;
  color: $jp-red;

  @include tablet {
    background-color: #fff;
    font-weight: 500;
  }
}

.link:hover {
    font-weight: 700;
}

.list {
  font-size: 1.5rem;
  padding: 1rem 1.4rem;
  line-height: 2;
  position: absolute;
  width: 100%;
  background-color: $jp-tan;
  margin: 19rem 0 0 -1rem;
  display: none;
  flex-direction: column;

  @include tablet {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    margin: 5rem 0;
  }

  @include desktop {
    width: 40%;
  }
}

.list.open {
  display: block;
}

.list__item {
  display: flex;
  flex-direction: column;

  @include tablet {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
}
